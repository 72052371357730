import React, { Component } from 'react';

import NavigationComponent from './NavigationComponent.js'

import TaskListComponent from './TaskList/TaskListComponent.js'
import AttributionComponent from './Attribution/AttributionComponent.js'

class RouterComponent extends Component {

  StateEnum = Object.freeze({
     "WHEN_DID_I_LAST":1,
     "ATTRIBUTIONS": 2
   })

  constructor(props) {
    super();
    this.props = props;
    this.state = {
        screen: this.StateEnum.WHEN_DID_I_LAST,
        name: "unknown",
        showMobileSubMenu: false
    };
  }

  componentDidMount() {

  }

  render() {

    let screen = <TaskListComponent isTabletOrMobile={this.props.isTabletOrMobile} />
    switch (this.state.screen) {
        case this.StateEnum.WHEN_DID_I_LAST:
          screen = <TaskListComponent isTabletOrMobile={this.props.isTabletOrMobile} />
          break;
        case this.StateEnum.ATTRIBUTIONS:
          screen = <AttributionComponent isTabletOrMobile={this.props.isTabletOrMobile} />
          break;
        default:
          break;
    }

    return (
      <div className="App">
        <NavigationComponent
          isTabletOrMobile={this.props.isTabletOrMobile} />
        {screen}

        <div className="footerContainer">
          <a href="/#" style={{marginRight: '30px'}}>attributions</a>
          <a href="/#">about</a>
        </div>
      </div>
    );
  }

}

export default RouterComponent;
