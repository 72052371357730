import React, { Component } from 'react';
import './TaskComponent.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faCheckCircle } from '@fortawesome/free-solid-svg-icons'

class TaskUncompletedComponent extends Component {

  constructor(props) {
    super();
    this.props = props;

  }

  click = () => {
    this.props.notifyDone();
  }

  render() {

    return (
      <div className="taskUncompleted"
           style={{borderColor : this.props.colour}}
           onClick={this.click}>
        <h1 style={{color : this.props.colour}}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </h1>
      </div>
    );
  }

}

export default TaskUncompletedComponent;
