import React, { Component } from 'react';
import './TaskComponent.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons'

class TaskTopBarComponent extends Component {

  constructor(props) {
    super();
    this.props = props;

    this.state = {
      title: this.props.item.title,
      occurance: this.props.item.occurance,
      timeFrame: this.props.item.timeFrame
    }

    if (this.props.item.newItem) {
      this.state.editMode = true;
    }
  }

  enterSaveMode = () => {
    var updatedItem = this.props.item;
    updatedItem.title = this.state.title;
    updatedItem.occurance = this.state.occurance;
    updatedItem.timeFrame = this.state.timeFrame;

    delete updatedItem.newItem;

    this.props.notifyItemUpdated(updatedItem);

    this.setState({editMode: false})
  }

  enterEditMode = () => {
    this.setState({editMode: true})
    this.props.notifyItemEdited();
  }

  handleTitleChanged = (e) => {
    this.setState({title: e.target.value});
  }

  handleOccuranceChanged = (e) => {
    this.setState({occurance: e.target.value});
  }

  handleTimeFrameChanged = (e) => {
    this.setState({timeFrame: e.target.value})
  }

  render() {

    var titleText = <h1>{this.state.title}</h1>
    var subtitleText = <h2 style={{marginTop:'5px'}}>
              every &#123;{this.state.occurance}&#125; &#123;{this.state.timeFrame}&#125;
              <a href="/#" onClick={this.enterEditMode}><FontAwesomeIcon icon={faPencilAlt} /></a></h2>
    var summarySubtitle = this.state.timeFrame
    var summaryTitle = (this.state.occurance - this.props.item.difference);

    if (this.state.editMode) {
      titleText = <input type="text" hint="title of the task" value={this.state.title}
      onChange={this.handleTitleChanged}/>
      subtitleText = <h1 style={{marginTop:'5px'}}>
                      every{' '}
                      <input className="occuranceTextBox" type="text"
                      value={this.state.occurance} onChange={this.handleOccuranceChanged} />
                      <select onChange={this.handleTimeFrameChanged}>
                        <option value="days">days</option>
                        <option value="hours">hours</option>
                      </select>
                      </h1>
      summarySubtitle = "";
      summaryTitle = <a style={{fontSize:'2em'}} href="/#" onClick={this.enterSaveMode}><FontAwesomeIcon icon={faSave} /></a>
    }

    return (
      <div className="taskTopBar" style={{background : this.props.colour}}>

      <div className="taskTopBarText">
        {titleText}
        {subtitleText}
      </div>
        <div className="taskTopBarSummary">
          <h2>{summarySubtitle}</h2>
          <h1 style={{marginTop:'5px'}}>{summaryTitle}</h1>
        </div>

      </div>
    );
  }

}

export default TaskTopBarComponent;
