import React, { Component } from 'react';
import './TaskListComponent.css';

import TaskComponent from '../Task/TaskComponent.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import {faCalendarPlus } from '@fortawesome/free-solid-svg-icons'

class TaskListComponent extends Component {

  constructor(props) {
    super();
    this.props = props;
    this.state = {
      items: [ ],
    };

  }

  addNewTask =() => {
    let newItem = {
      id: moment().unix(),
      title: "new task",
      occurance: 99,
      timeFrame: "days",
      status: "not soon",
      history: [],
      newItem: true
    }

    var items = this.state.items;
    items.push(newItem);

    this.setState({items: items});
  }

  componentDidMount() {
    this.readLocal();
  }

  itemUpdate = (updatedItem) => {

    var newItems = []
    for (var i in this.state.items) {
      var item = this.state.items[i];

      if (item.id === updatedItem.id) {
        newItems.push(updatedItem)
      } else {
        newItems.push(item)
      }

    }
    this.setState({items: newItems})

    this.saveLocal();
  }

  saveLocal = () => {
    console.log('saving...');
    localStorage.setItem("whendidilast_items", JSON.stringify(this.state.items) );
  }

  readLocal = () => {
    console.log("reading items");
    let json = localStorage.getItem("whendidilast_items");
    let items = JSON.parse(json);

    console.log(items)

    if (items === null || items.length === 0) {
      console.log("no items - creating a new array")
      items = []
    }

    this.setState({items: items});
  }

  render() {

    var containerClass = "taskListContainer"
    if (this.props.isTabletOrMobile) {
      containerClass += " taskListContainerMobile"
    } else {
      containerClass += " taskListContainerDesktop"
    }

    console.log("we have items: " + this.state.items.length)

    return (
      <div className={containerClass}>

        <div className="taskListTopBar">
          <button onClick={this.addNewTask} className="button-primary">
          <FontAwesomeIcon icon={faCalendarPlus} /></button>
        </div>

        {
            this.state.items.map(item => {
              return <TaskComponent
                  key={item.id}
                  item={item}
                  notifyItemUpdated={this.itemUpdate}
                  isTabletOrMobile={this.props.isTabletOrMobile} />
            })
          }

      </div>
    );
  }

}

export default TaskListComponent;
