import React, { Component } from 'react';
import './TaskComponent.css';

import TaskTopBarComponent from './TaskTopBarComponent.js'
import TaskCompletedComponent from './TaskCompletedComponent.js'
import TaskUncompletedComponent from './TaskUncompletedComponent.js'

import moment from 'moment';

class TaskComponent extends Component {

  StatusEnum = Object.freeze({
     "LATE":1,
     "SOON": 2,
     "NOT_SOON": 3
   })

  constructor(props) {
    super();
    this.props = props;

    this.state = {
      item: this.props.item
    };

    if (this.props.item.newItem) {
      this.state.editMode = true;
    }

    this.state.item.difference = 0;

  }

  processItem = () => {
    var item = this.state.item;

    if (this.state.item.history.length === 0) {
      item.status = this.StatusEnum.NOT_SOON;
      item.colour = "#596188";
      this.setState({item: item});
      return;
    }

    // calculate time since last completed
    let now = moment();
    let unix = moment.unix(this.state.item.history[this.state.item.history.length -1].timestamp)
    let diff = moment.duration(now.diff(unix));

    console.log(diff);

    if (this.state.item.timeFrame === "days") {
      item.difference = Math.round(diff.asDays())
    } else {
      item.difference = Math.round(diff.asHours())
    }

    if (this.state.item.difference >= this.state.item.occurance) {
        // item is late
        item.status = this.StatusEnum.LATE;
        item.colour = "#d45c5a";
    } else if (this.state.item.difference >= (this.state.item.occurance - (this.state.item.occurance / 10 ) )) {
      // item is soon
      item.status = this.StatusEnum.SOON;
      item.colour = "#7c3451";
    } else {
      // item is not soon
      item.status = this.StatusEnum.NOT_SOON;
      item.colour = "#596188";
    }

    this.setState({item: item});
  }

  componentDidMount() {
    this.processItem();
  }

  itemUpdate = (updatedItem) => {
    this.setState({item: updatedItem})
    this.setState({editMode: false})
    this.props.notifyItemUpdated(updatedItem)
    this.processItem();
  }

  itemEdit = () => {
    this.setState({editMode: true})
  }

  itemDone = () => {
    console.log("item done")

    var history = this.state.item.history;
    history.push({timestamp: moment().unix()})

    var updatedItem = this.state.item;
    updatedItem.history = history;

    this.itemUpdate(updatedItem);

    this.processItem();
  }

  render() {

    var containerClass = "taskContainer"

    if (this.props.isTabletOrMobile) {
      containerClass += " taskContainerMobile"
    } else {
      containerClass += " taskContainerNotMobile"
    }

    let displayedItems = this.state.item.history.slice(Math.max(this.state.item.history.length - 3, 0))

    let stamps = []

    if (! this.state.editMode) {
        for (let i in displayedItems) {
          let item = displayedItems[i];

          stamps.push(
            <TaskCompletedComponent
              colour={this.state.item.colour}
              key={item.timestamp}
              timestamp={item.timestamp}
              timeFrame={this.state.item.timeFrame} />)
        }
        stamps.push(
          <TaskUncompletedComponent
            key="next"
            colour={this.state.item.colour}
            notifyDone={this.itemDone}/>)
    }

    return (
      <div
        className={containerClass}
        style={{borderColor : this.state.item.colour, boxShadow:`0px 0px 10px 0px ${this.state.item.colour}`}}>

        <TaskTopBarComponent
          item={this.props.item}
          colour={this.state.item.colour}
          notifyItemUpdated={this.itemUpdate}
          notifyItemEdited={this.itemEdit}
          />

        <div className="taskList">
          {stamps}
        </div>
      </div>
    );
  }

}

export default TaskComponent;
