import React, { Component } from 'react';
import './TaskComponent.css';
import moment from 'moment';

class TaskCompletedComponent extends Component {

  constructor(props) {
    super();
    this.props = props;

  }

  render() {

    let unix = moment.unix(this.props.timestamp)

    let date = unix.format("DD MMM")
    let year = unix.format("YYYY")

    let now = moment();

    var diff = moment.duration(now.diff(unix));
    let duration = 0

    if (this.props.timeFrame === "days") {
      duration = Math.round(diff.asDays())
    } else {
      duration = Math.round(diff.asHours())
    }


    return (

      <div className="taskCompleted" style={{borderColor : this.props.colour}}>
        <div className="taskCompletedText">
        <h1 style={{color : this.props.colour}}>{date}</h1>
        <h1 style={{color : this.props.colour}}>{year}</h1>
          <h2 style={{marginTop:'6px', color : this.props.colour}}>{duration} {this.props.timeFrame} ago</h2>
        </div>
      </div>
    );
  }

}

export default TaskCompletedComponent;
