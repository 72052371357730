import './reset.css';
import './App.css';

import RouterComponent from './RouterComponent.js'


import { useMediaQuery } from 'react-responsive'

function App() {

  const isTabletOrMobile = useMediaQuery({ maxDeviceWidth: 1224 })

  return (

    /*
    hilda colours
    dark red = 7c3451
    red=d45c5a
    orange=e99778
    purple=596188
    */


    /*
apple colours
red = ff3b30
purple = 5856d6
blue = 007aff
    */
    <RouterComponent
        isTabletOrMobile={isTabletOrMobile} />
  );
}

export default App;
