import React, { Component } from 'react';
import './AttributionComponent.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLaugh, faFlag} from '@fortawesome/free-solid-svg-icons'

class AttributionComponent extends Component {

  constructor(props) {
    super();
    this.props = props;

    this.state = {
      items: [
        {"key": 1, "title": "Freepik icons", "link": "https://www.flaticon.com/", "icon": faLaugh},
        {"key": 2, "title": "FontAwesome icons", "link": "https://fontawesome.com/", "icon": faFlag}
      ],
    };
  }

  render() {
    return (
      <div className="attributionsContainer">
        <h1>Attributions</h1>
        {
          this.state.items.map(item => {

            return <p key={item.key}><a href={item.link}><FontAwesomeIcon icon={item.icon} /> {item.title}</a></p>
          })
        }
      </div>
    );
  }

}

export default AttributionComponent;
